
import { Component, Vue } from 'vue-property-decorator';
import { Action, State, Mutation } from 'vuex-class';

import Identification from '@/components/Identification.vue';
import Questionary from '@/components/Questionary.vue';

@Component({
  components: {
    Identification,
    Questionary,
  },
})
export default class Home extends Vue {

  @State((state) => state.loading) public loading!: boolean;
  @State((state) => state.callbackUrl) public callbackUrl!: string;
  @State((state) => state.rut) public userRut!: string;
  @State((state) => state.showQuestionary) public showQuestionary!: boolean;
  @State((state) => state.institution) public institution!: string;
  @Mutation('SET_SHOW') public setShow!: (value: any) => void;
  @Action('setInstitution') public setInstitution!: () => void;
  public errorMessage: string | null = null;

  public async created(): Promise<void> {
    await this.setInstitution();

    if (!this.callbackUrl) {
      this.errorMessage = 'Falta parámetro callback';
    } else {
      this.errorMessage = (new URL(window.location.href).searchParams.get('message'));
    }

    if (this.userRut) {
      this.setShow(true);
    }
  }
}
