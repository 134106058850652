import Vue from 'vue';
import Vuex, { ActionContext, MutationTree, GetterTree, ActionTree } from 'vuex';

import Axios from 'axios';

export interface Question {
  id: string;
  pregunta: string;
  respuestas: Array<{
    value: string;
    label: string;
  }>;
}

export interface Answer {
  idRespuesta: string;
  idPregunta: string;
}

export interface Store {
  rut: string;
  callbackUrl: string;
  institution: string | null;
  serial: string;
  glosa: string;
  message: string;
  showQuestionary: boolean;
  questions: Question[];
  token: string | null;
  audit: string;
  loading: boolean;
}

Vue.use(Vuex);

function cleanRut(value: any): string {
  return typeof value === 'string'
    ? value.replace(/[^0-9kK\-]+/g, '').toUpperCase()
    : '';
}

function validRut(rut: string): boolean | string {
  if (rut && rut.length < 8) {
    return true;
  }

  if (!/^0*(\d{1,3}(\.?\d{3})*)-?([\dkK])$/.test(rut)) {
    return 'RUT no válido';
  }

  rut = cleanRut(rut);
  let t = parseInt(rut.slice(0, -1), 10);
  let m = 0;
  let s = 1;

  while (t > 0) {
    s = (s + (t % 10) * (9 - m++ % 6)) % 11;
    t = Math.floor(t / 10);
  }

  const v = s > 0 ? '' + (s - 1) : 'K';
  return (v === rut.slice(-1)) || 'RUT no válido';
}

export const mutations: MutationTree<Store> = {
  SET_INSTITUTION(state: Store, value: string): void {
    state.institution = value;
  },
  SET_RUT(state: Store, value: string): void {
    state.rut = value;
  },
  SET_SERIAL(state: Store, value: string): void {
    state.serial = value;
  },
  SET_AUDIT(state: Store, value: string): void {
    state.audit = value;
  },
  SET_GLOSA(state: Store, value: string): void {
    state.glosa = value;
  },
  SET_MESSAGE(state: Store, value: string): void {
    state.message = value;
  },
  SET_QUESTIONS(state: Store, values: Question[]): void {
    state.questions = values;
  },
  SET_TOKEN(state: Store, value: string): void {
    state.token = value;
  },
  SET_LOADING(state: Store, value: boolean): void {
    state.loading = value;
  },
  SET_SHOW(state: Store, value: boolean): void {
    state.showQuestionary = value;
  },
  RESET_GLOSA(state: Store): void {
    state.glosa = 'Error';
  },
};

export const actions: ActionTree<Store, any> = {
  async setInstitution({ commit }: ActionContext<Store, any>): Promise<void> {
    commit('SET_LOADING', true);
    const { data } = await Axios.get('/api/institution');
    commit('SET_INSTITUTION', data.institution);
    commit('SET_LOADING', false);
  },
  setRut({ commit }: ActionContext<Store, any>, value: string): void {
    commit('SET_RUT', value);
  },
  setSerial({ commit }: ActionContext<Store, any>, value: string): void {
    commit('SET_SERIAL', value);
  },

  validRut(context: ActionContext<Store, any>, rut: string): string | boolean {
    return validRut(rut);
  },

  async getQuestions(ctx: ActionContext<Store, any>): Promise<void> {
    ctx.commit('SET_LOADING', true);
    ctx.commit('RESET_GLOSA');
    const { data } = await Axios.post('/api/questions', {
      rut: ctx.getters.cleanedRut,
      serial: ctx.state.serial,
      institution: ctx.state.institution,
    });

    if (data.glosa !== null && data.glosa !== '') {
      ctx.commit('SET_GLOSA', data.glosa);
    }

    if (!data.preguntas || data.preguntas.length === 0) {
      const redirectTo = ctx.getters.redirectUri;
      window.location = redirectTo;
    } else {
      ctx.commit('SET_QUESTIONS', data.preguntas);
      ctx.commit('SET_TOKEN', data.token);
      ctx.commit('SET_LOADING', false);
    }

  },

  async validateQuestions(ctx: ActionContext<Store, any>, responses: Answer[]): Promise<void> {
    ctx.commit('SET_LOADING', true);
    const { data } = await Axios.post('/api/validate', {
      rut: ctx.getters.cleanedRut,
      serial: ctx.state.serial,
      institution: ctx.state.institution,
      token: ctx.state.token,
      answers: responses,
    });

    ctx.commit('SET_GLOSA', data.glosa);
    ctx.commit('SET_MESSAGE', data.message);
    ctx.commit('SET_AUDIT', data.nroAuditoria || '');

    if (!data.preguntas || data.preguntas.length === 0) {
      const redirectTo = ctx.getters.redirectUri;
      window.location = redirectTo;
    } else {
      ctx.commit('SET_QUESTIONS', data.preguntas);
      ctx.commit('SET_LOADING', false);
    }
  },

};

export const getters: GetterTree<Store, any> = {
  cleanedRut(state: Store): string {
    return cleanRut(state.rut);
  },
  isValidRut(state: Store): string | boolean {
    return validRut(state.rut);
  },
  hasQuestionary(state: Store): boolean {
    return state.showQuestionary && state.questions.length > 0;
  },
  redirectUri(state: Store): string {

    let glosa;
    let message;

    switch (state.glosa) {
      case 'ExamenAprobado':
      case 'ExamenRechazado':
      case 'ValidacionFallo':
      case 'ErrorCreandoCuestionario':
      case 'SinExamen':
      case 'ErrorBuro':
          glosa = state.glosa;
          message = state.message;
          break;

      default:
        glosa = 'Error';
        message = state.glosa || 'Ocurrió un error';
        break;
    }

    const params = `audit=${state.audit}&glosa=${glosa}&message=${message}`;
    return state.callbackUrl.match(/\?/)
      ? `${state.callbackUrl}&${params}`
      : `${state.callbackUrl}?${params}`;
  },
};

export default new Vuex.Store({
  state: ({
    callbackUrl: (new URL(window.location.href)).searchParams.get('callback') || '',
    institution: '',
    rut: (new URL(window.location.href)).searchParams.get('rut') || '',
    serial: '000000',
    glosa: 'Error',
    message: 'Ocurrió un error',
    showQuestionary: false,
    questions: [],
    token: null,
    audit: '',
    loading: false,
  }),

  mutations,
  getters,
  actions,
});
