
import { Component, Vue } from 'vue-property-decorator';
import { Action, Mutation, Getter } from 'vuex-class';

@Component({})
export default class Identification extends Vue {

  @Action('setRut') public setRut!: (value: string) => void;
  @Action('setSerial') public setSerial!: (value: string) => void;
  @Getter('isValidRut') public isValidRut!: string | boolean;
  @Getter('cleanedRut') public getRut!: string;
  @Mutation('SET_SHOW') public setShow!: (value: any) => void;


  // Validations
  public required(v: string): string | boolean {
    return !!v || 'Este campo es obligatorio';
  }

   public validRut(): string | boolean {
     return this.isValidRut;
  }

  public handleFormSubmit(e: Event): void {
    e.preventDefault();
    if ((this as any).$refs.form.validate()) {
      this.setShow({ value: true });
    }
  }

}
