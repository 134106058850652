
import { Component, Vue } from 'vue-property-decorator';
import { State, Action, Getter } from 'vuex-class';
import { Question, Answer } from '@/store';

@Component({})
export default class Questionary extends Vue {

  @State((state) => state.questions) public questions!: Question[];
  @Action('validateQuestions') public validateQuestions!: any;
  @Getter public hasQuestionary!: boolean;


  public responses: Answer[] = [];

  public created(): void {
    this.$store.dispatch('getQuestions');
  }

  public required(v: string): boolean | string {
    return !!v || 'Este campo es obligatorio';
  }

  public updateQValue(id: string, value: string): void {
    this.responses.push({
      idRespuesta: value,
      idPregunta: id,
    });
  }

  public handleFormSubmit(e: Event): void {
    e.preventDefault();
    if ((this as any).$refs.form.validate()) {
      this.$store.dispatch('validateQuestions', this.responses);
    }
  }
}
